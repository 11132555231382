export const languageAliases = {
    en: {
        path: '/us',
        locale: 'en-CA',
        localeName: 'en',
        booxiLocale: 'eng'
    },
    fr: {
        path: '/fr',
        locale: 'fr-CA',
        localeName: 'fr',
        booxiLocale: 'fre'
    }
}
