import {gql} from 'graphql-request'
import {
    LINK_FRAGMENT_FOR_LARGE_QUERY,
    MEDIA_FRAGMENT_FOR_LARGE_QUERY
} from './fragments'

export const getDashboardPageQuery = gql`
    ${MEDIA_FRAGMENT_FOR_LARGE_QUERY}
    ${LINK_FRAGMENT_FOR_LARGE_QUERY}
    query PageCollection($locale: String!, $slug: String!) {
        pageCollection(where: {slug: $slug}, limit: 1, locale: $locale) {
            items {
                sectionsCollection(limit: 10) {
                    items {
                        __typename
                        ... on TextAsset {
                            name
                            textInAsset
                            subText
                            media {
                                ...MediaFragmentForLargeQuery
                            }
                            text
                            cta {
                                ...LinkFragmentForLargeQueryFragment
                            }
                        }
                    }
                }
            }
        }
    }
`
