import {useEffect, useRef} from 'react'
import {useLocation} from 'react-router-dom'

export const useHashFragment = (offset = 0) => {
    const scrolledRef = useRef(false)
    const {hash} = useLocation()
    const hashRef = useRef(hash)

    useEffect(() => {
        if (hash) {
            if (hashRef.current !== hash) {
                hashRef.current = hash
                scrolledRef.current = false
            }

            if (!scrolledRef.current) {
                setTimeout(() => {
                    const id = hash.replace('#', '')
                    const element = document.getElementById(id)
                    if (element) {
                        window.scrollTo({
                            top: element.offsetTop - offset,
                            behavior: 'smooth'
                        })
                        scrolledRef.current = true
                    }
                }, 1000)
            }
        }
    }, [hash, offset])
}
