import {Icon} from '@salesforce/retail-react-app/app/components/shared/ui'
import PropTypes from 'prop-types'
import React from 'react'

export const RoundDotIcon = ({isChecked}) => {
    return (
        <>
            {isChecked ? (
                <Icon viewBox="0 0 200 200" width="14px" height="14px">
                    <circle cx="100" cy="100" r="60" fill="black" />
                </Icon>
            ) : null}
        </>
    )
}

RoundDotIcon.propTypes = {
    isChecked: PropTypes.bool
}
