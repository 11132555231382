import {
    Box,
    ListItem,
    Text,
    useMultiStyleConfig
} from '@salesforce/retail-react-app/app/components/shared/ui'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, {useMemo} from 'react'
import {useIntl} from 'react-intl'

import {colors, styleConfigChakraKeys} from '../../constants'
import {getTitleCase} from '../../hooks/use-title-case'
import {MegaNavRightArrowIcon} from '../icons'
import Link from '../link'

const svgActiveState = {opacity: '1'}

export default function MegaLink({
    isLink,
    item,
    onLinkClick,
    setNextCategoryColumn,
    iValue,
    activeLinkId,
    setActiveLinkId
}) {
    const intl = useIntl()
    const styles = useMultiStyleConfig(styleConfigChakraKeys.megaLink)
    const isActive = useMemo(
        () => activeLinkId === item?.sys?.id,
        [activeLinkId, item?.sys?.id]
    )

    const handleNavLinkHover = () => {
        setNextCategoryColumn && setNextCategoryColumn([])
        setNextCategoryColumn(item?.itemsCollection?.items || [])
        setActiveLinkId(item?.sys?.id)
    }

    const handleMouseLeave = () => {
        // Optionally, clear the active state when mouse leaves
        setActiveLinkId()
    }

    return (
        <>
            {isLink ? (
                <ListItem display="flex">
                    <Link
                        {...styles.linkItem}
                        {..._.omit(item, ['text'])}
                        url={_.toLower(item.url)}
                        onLinkClick={() => {
                            if (item.isDisabled) {
                                return
                            }
                            onLinkClick()
                        }}
                        style={{'--index': iValue}}
                        {...(isActive ? styles.activeState : {})}
                        onClick={() => {
                            if (item.isDisabled) {
                                return
                            }
                            onLinkClick()
                        }}
                        {...(item.isDisabled ? {pointerEvents: 'none'} : {})}
                        color={item.isDisabled ? colors.grayish : '#000000'}
                    >
                        <Text {...styles.linkItemText}>
                            {item?.text}
                        </Text>
                    </Link>
                </ListItem>
            ) : (
                <ListItem
                    onMouseEnter={handleNavLinkHover}
                    onMouseLeave={handleMouseLeave}
                >
                    <Box
                        style={{'--index': iValue}}
                        {...styles.linkItem}
                        {...(isActive ? styles.activeState : {})}
                        {...(item.isDisabled ? {pointerEvents: 'none'} : {})}
                    >
                        <Text
                            {...styles.linkItemText}
                            cursor="pointer"
                            color={item.isDisabled ? colors.grayish : '#000000'}
                        >
                            {item.heading}
                            <MegaNavRightArrowIcon
                                opacity="0"
                                verticalAlign="middle"
                                marginTop="2px"
                                marginLeft="22px"
                                {...(isActive ? svgActiveState : {})}
                            />
                        </Text>
                    </Box>
                </ListItem>
            )}
        </>
    )
}

MegaLink.propTypes = {
    isLink: PropTypes.bool,
    item: PropTypes.object,
    onLinkClick: PropTypes.func,
    setNextCategoryColumn: PropTypes.func,
    iValue: PropTypes.number,
    activeLinkId: PropTypes.string,
    setActiveLinkId: PropTypes.func
}
