import {contentfulQueryKeys} from '../../../constants'
import {getMenuQuery} from '../queries'
import {useContentfulQueryWithHook} from './use-contentful-query.hook'

export const useMenuQuery = () => {
    const {data, error, isFetching} = useContentfulQueryWithHook(
        [...contentfulQueryKeys.getMenuQuery],
        getMenuQuery,
        {}
    )

    const menuItems =
        data?.menuCollection?.items[0]?.list?.itemsCollection?.items ?? []

    return {menuItems, error, isFetching}
}
