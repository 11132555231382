import {gql} from 'graphql-request'
import {LINK_FRAGMENT} from './fragments'

export const getMenuQuery = gql`
    ${LINK_FRAGMENT}
    query Menu($locale: String!) {
        menuCollection(limit: 1, locale: $locale) {
            items {
                list {
                    sys {
                        id
                    }
                    name
                    heading
                    __typename
                    itemsCollection(limit: 5) {
                        items {
                            ... on Link {
                                ...LinkFragment
                            }
                            ... on List {
                                sys {
                                    id
                                }
                                name
                                heading
                                __typename
                                itemsCollection(limit: 10) {
                                    items {
                                        ... on Link {
                                            ...LinkFragment
                                        }
                                        ... on List {
                                            sys {
                                                id
                                            }
                                            name
                                            heading
                                            __typename
                                            itemsCollection(limit: 10) {
                                                items {
                                                    ... on Link {
                                                        ...LinkFragment
                                                    }
                                                    ... on List {
                                                        heading
                                                        __typename
                                                        itemsCollection(
                                                            limit: 10
                                                        ) {
                                                            items {
                                                                ... on Link {
                                                                    ...LinkFragment
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`
