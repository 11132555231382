/* eslint-disable jsx-a11y/no-distracting-elements */
import PropTypes from 'prop-types'
import React, {forwardRef} from 'react'

import {Box, Flex} from '@salesforce/retail-react-app/app/components/shared/ui'

import Link from '../../link'

const AnnouncementBar = forwardRef(({header}, ref) => {
    const announcementBar = header?.headerCollection?.items[0]?.announcementBar
    const theme = announcementBar?.theme
    const announcementItems = announcementBar?.notificationsCollection?.items

    if (!announcementBar || !announcementItems?.length) {
        return
    }

    return (
        <Box
            backgroundColor={theme ? theme.backgroundColor : 'black'}
            color={theme?.color || 'white'}
            fontSize={{base: '12px', lg: '14px'}}
            fontWeight="200"
            textAlign="center"
            ref={ref}
        >
            <Flex
                display="flex"
                styleType="unset"
                padding="10px"
                fontFamily={`'Raleway', sans-serif`}
                fontStyle="italic"
                mx="auto"
            >
                {/* 
                    INFO: Implementation for single announcement item
                */}
                {announcementItems?.slice(0, 1)?.map((announcementLink) => {
                    return (
                        <Link
                            buttonStyles={{
                                color: theme ? theme.color : 'white',
                                fontSize: '12px',
                                fontWeight: '400',
                                _hover: {
                                    textDecoration: 'none'
                                },
                                margin: 'auto',
                                whiteSpace: 'unset',
                                height: 'fit-content'
                            }}
                            {...announcementLink}
                            type={announcementLink.type || 'tertiary'}
                            key={announcementLink?.sys?.id}
                        />
                    )
                })}
            </Flex>
        </Box>
    )
})

AnnouncementBar.propTypes = {
    header: PropTypes.object
}
AnnouncementBar.displayName = 'AnnouncementBar'

export default AnnouncementBar
